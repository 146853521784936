/***
 * Bunch of code that was in index.html. have no idea what it was doing.. but lets stop adding script tags to index.html please..
 */
(function () {
    const { $ } = window;
    $('body').on('focus', 'input[type=number]', function (e) {
        $(this).on('mousewheel', function (e) {
            e.preventDefault();
        });
    });
    $('body').on('blur', 'input[type=number]', function (e) {
        $(this).off('mousewheel');
    });

    let lastTime = 0;
    const vendors = ['ms', 'moz', 'webkit', 'o'];
    for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame =
            window[vendors[x] + 'CancelAnimationFrame'] || window[vendors[x] + 'CancelRequestAnimationFrame'];
    }
    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (func, element) {
            const currTime = new Date().getTime();
            const timeToCall = Math.max(0, 16 - (currTime - lastTime));
            const id = window.setTimeout(function () {
                func(currTime + timeToCall);
            }, timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };
    }
    if (!window.cancelAnimationFrame) {
        window.cancelAnimationFrame = function (id) {
            clearTimeout(id);
        };
    }

    $(function () {
        setTimeout(() => window.angular.bootstrap(document, ['hippo'], { strictDi: true }), 1);
    });
})();
